import React from "react"
import NoHeaderFooterLayout from "./body/noHeaderFooterLayout"
import PropTypes from "prop-types"
import { StateProvider } from "../contextApi/index"

const NoheaderFooter = ({ children }) => {
  const initialState = {
    mobile: false,
    error: false,
    success: {
      message: "",
      status: false,
      header: "",
      button: "",
    },
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_MOBILE":
        return {
          ...state,
          mobile: action.newMobile,
        }
      case "CHANGE_ERROR":
        return {
          ...state,
          error: action.newPayload,
        }
      case "CHANGE_SUCCESS_MODAL":
        return {
          ...state,
          success: action.newPayload,
        }
      default:
        return state
    }
  }

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <NoHeaderFooterLayout children={children} />
    </StateProvider>
  )
}

NoheaderFooter.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NoheaderFooter
