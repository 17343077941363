import { Link } from "gatsby";
import React from "react";

const Panel = React.memo( function Panel({ question, answer, activeTab, index, activateTab, showGrey, setSelectedItem, selectedItem, QuestionIcon, icon, questionStyles, textStyles }) {
    const isActive = activeTab === index;

    return (
        <div className={`panel panelled panelled-moble`} role="tabpanel" aria-expanded={isActive}>
            <div className={`label panel__label color-black label-mobile ${showGrey ? "background-grey" : ""}`} role="tab" onClick={() => activateTab(index)}>
                <div className={`flex justify-between ${questionStyles}`}>
                    {icon &&
                        <div className="avatar-wrap no-border margin-left-3 align-self-center flag-icon">
                            <div className="">
                                {QuestionIcon && QuestionIcon}
                            </div>
                        </div>
                    }
                    <span className={`align-self-center ${textStyles}`}>{question}</span>
                </div>
                <span>
                    <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                            fill="#C7C7CC"
                        ></path>
                    </svg>   
                </span>
            </div>
            <div className="panel__inner inner__text text-left mobile-text" aria-hidden={!isActive}>
                {answer.map(({ title, link, id, image }, index) => (
                    <Link
                        to={link}
                        key={index}
                        className="pos-relative"
                        onClick={() => setSelectedItem(id)}>
                        <div className={`${selectedItem === id ? "item-mobile-indicator" : ""}`}>
                            <div className={`${selectedItem === id ? "item-mobile-indicator-item" : ""}`} />
                        </div>
                        <div className={`content-section panel__content`} style={{
                            backgroundColor: id === selectedItem ? "#F5F6FE" : "", color: id === selectedItem ? "mobile-color" : "var(--blackColor)", fontWeight: id === selectedItem ? "700" : "500"
                        }}>
                            <div className="flex">
                                {icon &&
                                    <div className="avatar-wrap no-border margin-left-3 align-self-center">
                                        <div className="align-self-center">
                                            {image && image}
                                        </div>
                                    </div>
                                }
                                {title}
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
});

export default Panel;
